<template>
  <AView
    :value="value"
    :actions="actions"
    :active="term.id === activeTermId"
    :name="term.name"
    ref="term-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    @action="(action) => action.handler({ from: value, term })"
  >
    <template v-slot:content="{}">
      <GlossaryTermDetails
        :value="term"
        :permissions="value.permissions"
        @onTermClick="onTermClick"
        @action="(action) => action.handler({ from: value, term })"
      ></GlossaryTermDetails>
    </template>
  </AView>
</template>
  
  
<script>
import { mapGetters, mapState } from "vuex";
import { PermissionsHelper } from "../../../../../../components/helpers/permissions.helper";
import GlossaryTermDetails from "../../../../../../components/wad/organisms/glossary/GlossaryTermDetails.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import { GLOSSARY_BUTTONS } from "../../constants/actions.gh";
import { GLOSSARY_PERMISSIONS } from "../../constants/permissions.gh";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    GlossaryTermDetails,
  },
  data: () => ({
    GLOSSARY_PERMISSIONS,
    term: {},
  }),
  computed: {
    ...mapState("GlossaryStore", ["activeTermId", "displayedTerms"]),
    ...mapGetters("GlossaryStore", ["termById"]),
    actions: function () {
      return PermissionsHelper.getActions(
        GLOSSARY_BUTTONS.GENERAL_EDITOR_MAIN,
        this.value.permissions
      );
    },
  },
  async created() {
    await this.$store.dispatch("GlossaryStore/GetTerm", {
      id: this.value.relation.params.termId,
    });

    this.term = this.termById(this.value.relation.params.termId);
  },
  methods: {
    onHover() {
      this.$store.commit(
        "GlossaryStore/setActiveId",
        this.value.relation.params.termId
      );
    },
    onTermClick(term) {
      this.$store.dispatch("GlossaryStore/SetActiveTerm", {
        from: this.value,
        term,
      });
    },
    onTermRemove() {},
  },
  watch: {
    displayedTerms: {
      async handler(newVal) {
        if (newVal && !newVal.IN_PROGRESS) {
          this.term = Object.assign(
            this.term,
            this.termById(this.value.relation.params.termId)
          );
        }
      },
      deep: true,
    },
  },
};
</script>
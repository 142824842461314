<template>
  <v-card class="transparent mt-4 pa-2 elevation-0">
    <v-card-title>
      {{ String(value.name).toUpperCase() }}
    </v-card-title>
    <!-- Domains -->
    <FormSection
      class="mt-0"
      :label="'Business Domains'"
      :editable="false"
      underline
      v-if="value.domains && value.domains.length"
    ></FormSection>
    <v-slide-group class="pa-2" center-active show-arrows>
      <v-slide-item v-for="item in value.domains" :key="item.id">
        <BusinessDomainItemSmall
          @click="() => onDomainClick(item.domain)"
          :value="item.domain"
        ></BusinessDomainItemSmall>
      </v-slide-item>
    </v-slide-group>
    <v-card-text>
      <h3 class="my-2">DEFINITION:</h3>
      <p class="simple-text" v-html="value.description"></p>
      <v-slide-group
        v-model="tagIndex"
        class="my-4"
        center-active
        show-arrows
        v-if="value.tags && value.tags.length"
      >
        <v-slide-item
          v-for="item in value.tags"
          :key="item.tag.id"
          v-slot="{ toggle }"
        >
          <v-chip
            class="ma-2"
            :color="item.tag.color"
            @click="toggle"
            label
            text-color="white"
          >
            <v-icon v-if="item.tag.icon" left> {{ item.tag.icon }} </v-icon>
            {{ item.tag.name }}
          </v-chip>
        </v-slide-item>
      </v-slide-group>

      <h3 class="my-2">SIMPLER:</h3>
      <p class="simple-text" v-html="value.non_technical_description"></p>

      <h3 class="my-2">LEGAL DEFINITION:</h3>
      <p class="simple-text" v-html="value.legal_description"></p>

      <!-- Synonyms -->
      <FormSection
        :label="'Synonyms'"
        :icon="'mdi-approximately-equal'"
        underline
        right
        :actions="actions"
        :editable="!!actions.length"
        @action="(action) => $emit('action', action)"
      ></FormSection>

      <v-slide-group class="pa-2" center-active show-arrows>
        <v-slide-item v-for="item in value.synonyms" :key="item.term.id">
          <GlossaryTermItemSmall
            @click="() => $emit('onTermClick', item.term)"
            :value="item.term"
          ></GlossaryTermItemSmall>
        </v-slide-item>
      </v-slide-group>

      <!-- Linked Terms -->
      <FormSection
        :label="'Linked Terms'"
        :icon="'mdi-book-alphabet'"
        underline
        right
        :actions="actions"
        :editable="!!actions.length"
        @action="(action) => $emit('action', action)"
      ></FormSection>

      <v-slide-group class="pa-2" center-active show-arrows>
        <v-slide-item v-for="item in value.linked" :key="item.term.id">
          <GlossaryTermItemSmall
            @click="() => $emit('onTermClick', item.term)"
            :value="item.term"
          ></GlossaryTermItemSmall>
        </v-slide-item>
      </v-slide-group>

      <!-- Useful Links -->
      <FormSection
        :label="'Useful Links'"
        class="mt-0"
        :icon="'mdi-link-variant'"
        underline
        right
        :actions="actions"
        :editable="!!actions.length"
        @action="(action) => $emit('action', action)"
      ></FormSection>

      <LinksListVue
        v-if="value.links && value.links.length > 0"
        :value="value.links"
      ></LinksListVue>
    </v-card-text>
  </v-card>
</template>
    
    
<script>
import { GLOSSARY_BUTTONS } from "../../../../views/src/apps/knowledge-base/constants/actions.gh";
import { PermissionsHelper } from "../../../helpers/permissions.helper";
import FormSection from "../../atoms/common/FormSection.vue";
import BusinessDomainItemSmall from '../knowledgeBase/businessDomains/BusinessDomainItemSmall.vue';
import LinksListVue from "../knowledgeBase/links/LinksList.vue";
import GlossaryTermItemSmall from "./GlossaryTermItemSmall.vue";


export default {
  props: {
    value: {
      default: () => ({}),
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    FormSection,
    LinksListVue,
    GlossaryTermItemSmall,
    BusinessDomainItemSmall
  },
  data() {
    return {
      tagIndex: null,
    };
  },
  computed: {
    actions: function () {
      return PermissionsHelper.getActions(
        [GLOSSARY_BUTTONS.GENERAL_EDITOR, GLOSSARY_BUTTONS.LIST],
        this.permissions
      );
    },
  },
  async created() {},
  methods: {},
  watch: {},
};
</script>